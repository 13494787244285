import React from 'react';
import { Button, Row, Col, Container } from 'reactstrap';

class Hero extends React.Component {
  render() {
    return (
      <div className={`hero ${this.props.color}`}>
        <Container>
          {this.props.children}
        </Container>
      </div>
    )
  }
}

export default Hero;
