import React from 'react';
import { Container } from 'reactstrap';
import ServicesCard from './services-card';

class Services extends React.Component {
  render() {
    return (
      <div className={`services`}>
        <ServicesCard
          ltr
          imageShadow
          label="Consumer-focused"
          heading="Digital Customer Management"
          text="We will help manage your customers using the power of digital technology."
          img={'https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2550&q=80'}
        />

        <ServicesCard
          rtl
          dark
          imageShadow
          label="Specialist Technology"
          heading="Digital Technology Management"
          text="We will help with your digital technology strategy and advance your business."
          img={'https://images.unsplash.com/photo-1560732488-6b0df240254a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2550&q=80'}
        />

        <ServicesCard
          ltr
          nohr
          imageShadow
          label="Consultation Services"
          heading="Digital Operations"
          text="Our digital operations will streamline your business to prepare you for any future changes."
          img={'https://images.unsplash.com/photo-1573164713988-8665fc963095?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2550&q=80'}
        />
      </div>
    )
  }
}

export default Services;
