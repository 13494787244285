import React from 'react';
import { Container, Row, Col } from 'reactstrap';

class ServicesCard extends React.Component {
  render() {
    let hr;
    let shadow = '';
    let dark = '';

    if(this.props.dark) {
      dark = "dark";
    }
    if (!this.props.nohr) {
      hr = <hr/>
    }

    if (this.props.imageShadow) {
      //shadow = 'shadow';
    }
    let link;
    if (this.props.link && this.props.linkText) {
      link = <a href={this.props.link}>{this.props.linkText}</a>;
    }
    let smallImage = this.props.smallImage ? 'smallImage' : '';
    if (this.props.rtl) {
      return (
        <div className={`service-card ${dark}`} id={this.props.id}>
            <Row>
              <Col xs={12} md={6} className="align-self-center">
                <img src={this.props.img} className={`img-fluid service-card-image ${shadow} ${smallImage} d-none d-md-block`} />
              </Col>
              <Col xs={12} md={6} className="align-self-center service-card-text">
                <div className="service-label">{this.props.label}</div>
                <h4 className="service-heading">{this.props.heading}</h4>
                <p>{this.props.text}</p>
                {link}
              </Col>
              <Col xs={12}>
              </Col>
            </Row>
        </div>
      )
    }
    return (
      <div className={`service-card ${dark}`} id={this.props.id}>
          <Row>
            <Col xs={12} md={6} className="align-self-center service-card-text">
              <div className="service-label">{this.props.label}</div>
              <h4 className="service-heading">{this.props.heading}</h4>
              <p>{this.props.text}</p>
              {link}
            </Col>
            <Col xs={12} md={6} className="align-self-center">
              <img src={this.props.img} className={`img-fluid service-card-image ${shadow} ${smallImage} d-none d-md-block`} />
            </Col>
            <Col xs={12}>
            </Col>
          </Row>
      </div>
    )
  }
}

export default ServicesCard;
