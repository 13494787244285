import React from 'react';
import Navbar from '../components/navbar';
import Hero from '../components/hero';
import WhatWeDo from '../components/WhatWeDo';
import Services from '../components/services';
import Contact from '../components/contact';
import Layout from '../components/layout';
import {Row, Col, Button, Container } from 'reactstrap';
import logo from '../images/logo.jpg';
import OurApproach from '../components/our-approach';

class LandingSite extends React.Component {
  render() {
    return (
      <Layout>
        <Hero color="finteq">
          <Row>
            <Col xs={12} lg={7}>
              <h1>Finteq Services

              </h1>
              <p className="lead">We exist to help your telecommunications business adopt digital technology
                <br/>
                <br/>
                <span className="text-white"><a href="#contact">Get in touch</a> to find out more.</span></p>
            </Col>
          </Row>
        </Hero>
        <WhatWeDo id="about">
          <Row>
            <Col xs={'hidden'} lg="auto" className="align-self-center">
              <img src={logo} className="img-fluid"/>
            </Col>
            <Col xs={12} lg={8} className="align-self-center">
              <h4>What we do</h4>
              <p>
                Our aim is to future-proof you in digital transformation through our DT Programmes where we focus on
                Digital Customer Management, Digital Technology Management and Digital Operations.
              </p>
            </Col>
            <Col xs={12} lg={2} className="align-self-center">
              <a className="btn btn-primary" href="#contact">Contact Us</a>
            </Col>
          </Row>
        </WhatWeDo>
        <div className="background-grey">
          <Container className="services-container">
            <Row>
              <Col xs={12}>
                <h2 id="services">Services</h2>
              </Col>
            </Row>
            <Services />
          </Container>
        </div>
        <OurApproach id="our-approach"/>
        <Contact />
      </Layout>
    )
  }
}

export default LandingSite;
