import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import EmailSignUp from './email-signup';

class Contact extends React.Component {
  render() {
    return (
      <div className={`sign-up`} id={`contact`}>
        <Container>
          <Row>
            <Col xs={12}>
              <h2>Contact Us</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <p><a href="mailto: info@finteqservices.co.uk">Get in touch</a> to find out more about Finteq Services.</p>
              <p>We'd love to hear from you and work together, email us to find out more.</p>
            </Col>
            <Col xs={12} lg={6}>
              <address>
                C/O Spearmans 333 Edgware Road<br/>
                The Hyde<br/>
                London <br/>
                NW9 6TD<br/>
              </address>
              <p>Email us: <a href="mailto: info@finteqservices.co.uk">info@finteqservices.co.uk</a></p>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Contact;
