import React from 'react';
import { Container } from 'reactstrap';

class WhatWeDo extends React.Component {
  render() {
    return (
      <div className={`what-we-do`} id={this.props.id}>
        <Container>
          {this.props.children}
        </Container>
      </div>
    )
  }
}

export default WhatWeDo;
