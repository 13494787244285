import React from 'react';
import { Container, Row, Col, Button,Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';

class EmailSignUp extends React.Component {
  constructor() {
    super();
    this.signup = this.signup.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
      email: '',
      modal: false,
      sent: false
    }
  }
  onEmailChange(e) {
    this.setState({
      email: e.target.value,
      sent: false
    });
  }
  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }
  signup(e) {
    e.preventDefault();
    if (!this.state.email) {
      return;
    }
    axios.post('https://register.brego.io/signup', {
      email: this.state.email,
      message: 'Signed up from small form',
      fullName: 'Small Email Signup Form',
      phone: '',
      company: '',
    }).then(() => {
      this.setState({
        modal: true,
        email: '',
        sent: true
      })
    });
  }
  render() {
    return (
      <form onSubmit={this.signup}>
        <Row>
            <Col xs={8} lg={8}>
              <input type="email" value={this.state.email} onChange={this.onEmailChange} className="form-control" placeholder="Enter your email.." required/>
            </Col>
            <Col xs={4} lg={4}>
              <Button disabled={this.state.sent} color="white" block>Get Started</Button>
            </Col>
        </Row>

        <div>
          <Modal isOpen={this.state.modal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}>Thanks!</ModalHeader>
            <ModalBody>
              Thanks for signing up! We'll be in touch shortly!
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.toggle}>Close</Button>{' '}
            </ModalFooter>
          </Modal>
        </div>

      </form>
    )
  }
}


export default EmailSignUp;
