import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';

class OurApproach extends React.Component {
  render() {
    return (
      <div className={`approach`} id={this.props.id}>
        <Container>
          <Row>
            <Col xs={12}>
              <h2>Our Approach</h2>
              <p>Our methods have been tried and perfected through multiple iterations</p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <div className="approach-card">
                <div className="approach-card-time">Step 1</div>
                <div className="approach-card-desc">
                  Review the current business approach and create an assessment report to understand the gaps
                </div>
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <div className="approach-card">
                <div className="approach-card-time">Step 2</div>
                <div className="approach-card-desc">
                  Showcasing the technology available and explaining on how these gaps could be bridged with minimal interference to current operations
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default OurApproach;
